import Alert from "../../Alert";
import API from "../../Network/API";
import { employees, isLoaded, isLoading, details, taskComments, groupName, groupList, groupEmail } from "../action";// getting group chat details
export async function getGroupChatDetailsService(dispatch, getUser, data) {
  dispatch(isLoading());
  try {
    var response = await API.post("group_chat.php", {
      action: 'getGroupChatDetails',
      created_by: getUser.userName,
      group_Id: data.id,
    }, {}, false);
    if (response.status === 'True') {
      dispatch(groupName(response.data[0].group_name))
      dispatch(groupList(response.data[0].members_name.split(',')))
      dispatch(groupEmail(response.data[0].members_email.split(',')))
    }
    else {
      dispatch(details([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}

// get all messages to display in group chat modal
export async function getMessagesService(dispatch, getUser, data) {
    dispatch(isLoading());
    try {
      var response = await API.post("group_chat.php", {
        corp_code: getUser.corp,
        action: "getmessages",
        group_Id: data.id
      }, {}, false);
      if (response.status === 'True') {
        dispatch(taskComments(response.data));
      } else {
        dispatch(taskComments([]));
      }
    } catch (error) {
      Alert(error.message)
    }
    dispatch(isLoaded());
  }

  // for getting employee details
export async function getEmployeesService(dispatch, getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("agile_squads.php", {
        "crop": getUser.corp,
        projectId: getUser.projectId,
      }, {}, false);
      if (response.status === 'True') {
        dispatch(employees(response.data))
      }
      else {
        dispatch(employees([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }

  // for getting group details
export async function getGroupDetailsService(dispatch, getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("group_chat.php", {
        "crop": getUser.corp,
        created_by: getUser.userName,
      }, {}, false);
      if (response.status === 'True') {
        dispatch(details(response.data))
      }
      else {
        dispatch(details([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }