import React, { useState, useEffect } from 'react';

const RandomPhraseComponent = (props) => {
  // eslint-disable-next-line
  const [phrases, setPhrases] = useState([
    "Just a moment while I gather the information for you.",
    "I'm on it! Fetching the details you need.",
    "Thanks for your patience! Let me find that information for you.",
    "I'll quickly pull up the relevant details – one moment please.",
    "Great question! Let me grab the latest info for you.",
    "While I look that up, is there anything else you'd like to know?",
    "I'm diving into the data now. Any other specific details you're interested in?",
    "Hang tight! I'm getting the scoop on that for you.",
    "Your curiosity is contagious! Let me fetch the details.",
    "Exploring the database to get you the most accurate information, just a moment."

  ]);

  const getRandomPhrase = () => {
    const randomIndex = Math.floor(Math.random() * phrases.length);
    return phrases[randomIndex];
  };

  const [displayedPhrase, setDisplayedPhrase] = useState(getRandomPhrase());

  useEffect(() => {
    handleNewPhrase()
    // eslint-disable-next-line
  }, [props])

  const handleNewPhrase = () => {
    const newPhrase = getRandomPhrase();
    setDisplayedPhrase(newPhrase);
  };

  // Use useEffect to update the displayed phrase
  useEffect(() => {
    const timeout = setTimeout(() => {
      const newPhrase = getRandomPhrase();
      setDisplayedPhrase(newPhrase);
    }, 10000); // Change phrase every 5 seconds
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [displayedPhrase]);

  return (
    <div>
      <p className="fade-in-phrase">{displayedPhrase}</p>
    </div>
  );
};

export default RandomPhraseComponent;
