// import { JitsiMeeting } from '@jitsi/react-sdk';
import React from 'react';
// import Modal from '@material-ui/core/Modal';
// import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';
// import { makeStyles } from '@material-ui/core/styles';
import { Meet_URL } from '../config';
// import { useSelector } from 'react-redux';
import jwt from 'jsonwebtoken';
import { useState } from 'react';
import { useEffect } from 'react';
import { removeSpecialCharacters } from '../commonUsage';

// const useStyles = makeStyles(theme => ({
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   paper: {
//     width: '100%',
//     padding: theme.spacing(2, 4, 3),
//   },
// }));

const JitsiComponent = (props) => {

  // const classNames = useStyles();
  // const properties = useSelector(state => state.landingReducer.properties)
  const [token, setToken] = useState(null);
  // const getUser = useSelector(state => state.auth)
  const [meetingUrl, setMeetingUrl] = useState(null)

  useEffect(() => {
    // Function to generate JWT token
    const generateToken = () => {
      // Replace this secret key with a secure key
      const secretKey = "NOVISYNCWETASKER";

      // Sample payload data
      const payload = {
        "context": {
          "user": {
            "avatar": "",
            "name": "",
            "email": "",
          },
        },
        "aud": "jitsi",
        "iss": "98765432",
        "sub": Meet_URL,
        "room": removeSpecialCharacters(props.roomName),
        // "exp": 1700060038,
      };
      const generatedToken = jwt.sign(payload, secretKey);
      setToken(generatedToken);
      setMeetingUrl(`https://${Meet_URL}/${removeSpecialCharacters(props.roomName)}?jwt=${generatedToken}#jitsi_meet_external_api_id=98765432&config.startWithAudioMuted=true&config.startWithVideoMuted=true&config.autoCaptionOnRecord=true`)
    };

    generateToken();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {
        token !== null &&
        props.updateMeetingUrl(meetingUrl)
      }
    </>
    // <Modal
    //   aria-labelledby="transition-modal-title"
    //   aria-describedby="transition-modal-description"
    //   className={classNames.modal}
    //   open={props.open}
    //   onClose={props.handleClose}
    //   closeAfterTransition
    //   disableBackdropClick={true}
    //   BackdropComponent={Backdrop}
    //   BackdropProps={{
    //     timeout: 500,
    //   }}
    // >
    //   <Fade in={props.open}>
    //     <div className={classNames.paper} style={{ padding: '10px' }}>
    //       <p style={{ float: 'inline-end', color: 'white', cursor: 'pointer' }} onClick={props.handleMeetingEnd}>Close X</p>
    //       {
    //         token !== null &&
    //         <JitsiMeeting
    //           domain={Meet_URL}
    //           roomName={props.roomName}
    //           configOverwrite={{
    //             startWithAudioMuted: true,
    //             disableModeratorIndicator: true,
    //             startScreenSharing: true,
    //             enableEmailInStats: false
    //           }}
    //           interfaceConfigOverwrite={{
    //             DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,
    //             DEFAULT_LOGO_URL: properties?.IMG_SRC,
    //             SHOW_JITSI_WATERMARK: false
    //           }}
    //           onApiReady={(externalApi) => {
    //             props.updateMeetingUrl(externalApi._url)
    //           }}
    //           getIFrameRef={(iframeRef) => {
    //             iframeRef.style.height = '600px';
    //           }}
    //           onReadyToClose={props.handleMeetingEnd}
    //           jwt={token}
    //         />
    //       }
    //     </div>
    //   </Fade>
    // </Modal>
  )
};

export default JitsiComponent;
