import React, { useState, useEffect, useReducer, useRef } from 'react';
import './chatbot.scss';
import axios from 'axios'; // Use Axios for making API requests
import { useSelector } from 'react-redux';
import { initialState, reducer } from './reducer';
import { ChatBot_URL } from '../config';
import RandomPhraseComponent from './phrases';

function ChatBot() {
  const [chatData, setChatData] = useState([]);
  const [inputText, setInputText] = useState('');
  const [chatBoxOpen, setChatBoxOpen] = useState(false);
  // eslint-disable-next-line
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loader, setLoader] = useState(false)
  const webProperties = useSelector(state => state.landingReducer.webProperties)
  const APP_NAME = webProperties.APP_NAME;
  const chatBoxRef = useRef(null);
  const [messageText,setMessageText] = useState('')
  useEffect(() => {
    // When component mounts, check session storage and open chat if needed
    const chatboxStatus = sessionStorage.chatbox;
    if (chatboxStatus === 'open') {
      openChat();
    }
    // Load chat data from sessionStorage and append it to state
    const savedChatData = JSON.parse(sessionStorage.getItem('chatData'));
    if (savedChatData) {
      setChatData(savedChatData);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Save chat data to sessionStorage whenever chatData changes
    sessionStorage.setItem('chatData', JSON.stringify(chatData));
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatData]);

  const initializeChat = async () => {

    const botMessage = {
      text: "Welcome to Wetasker!",
      type: 'user',
    };

    // Check if the text property of botMessage is not already in the existing chat data
    if (!chatData.some(message => message.text === botMessage.text)) {
      setChatData((prevChatData) => [...prevChatData, botMessage]);
    }

  };

  const openChat = () => {
    setChatBoxOpen(true);
    sessionStorage.chatbox = 'open';
    initializeChat(); // Load chat data when chat opens
  };

  const closeChat = () => {
    setChatBoxOpen(false);
    sessionStorage.clear();
    sessionStorage.chatbox = 'close';
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
    setMessageText(e.target.value)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim() === '') {
      return;
    }
    setLoader(true)

    const userMessage = {
      text: inputText,
      type: 'self',
    };

    setChatData((prevChatData) => [...prevChatData, userMessage]);
    setInputText('');

    try {
      const response = await axios.post(`${ChatBot_URL}/get_response`, {
        user_input: inputText,
      });
      const botMessage = {
        text: response.data.response,
        type: 'user',
      };
      
      setChatData((prevChatData) => [...prevChatData, botMessage]);
      setMessageText('')
      setLoader(false)
    } catch (error) {
      const botMessage = {
        text: "Thank you for your contact information. Our team will be in touch soon.",
        type: 'user',
      };
      const errorMessage = {
        text: `I'm happy to help! Please provide your contact email address so that I can follow up with you.
        Thank you for your contact information. Our team will be in touch soon.`,
        type: 'user',
      };
      setLoader(false)
      setMessageText('')
      if (chatData.some(message => message.text === errorMessage.text)) {
        setChatData((prevChatData) => [...prevChatData, botMessage]);
      } else {
        setChatData((prevChatData) => [...prevChatData, errorMessage]);
      }
    }
  };

  return (
    <div>
      <div id="chat-circle" onClick={() => openChat()} className="btn btn-raised" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
        <div id="chat-overlay"></div>
        <i className="mdi mdi-comment" style={{ fontSize: "24px" }}></i>
      </div>

      {chatBoxOpen && (
        <div className="chat-box">
          {/* Chat Box Header */}
          <div className="chat-box-header" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
            <span id="chat-box-header" style={{ color: 'white' }}>{APP_NAME} Assistant</span>
            <span className="chat-box-toggle" onClick={closeChat}><i className="mdi mdi-close" style={{ fontSize: "24px", color: 'white' }}></i></span>
          </div>

          {/* Chat Box Body */}
          <div className="chat-box-body" >
            <div className="chat-box-overlay"></div>
            <div className="chat-logs" ref={chatBoxRef}>
              {chatData.map((message, index) => (
                <div key={index} className={`chat-msg ${message.type}`}>
                  <div className="cm-msg-text">
                    {message.type === 'user' && message.text}
                    {message.type === 'self' && message.text}
                    {/* {message.type === 'user' ? <><br/><label style={{fontSize: '10px', float: 'left'}}>Chatbot</label></> : null}
                    {message.type === 'self' ? <><br/><label style={{fontSize: '10px', float: 'left'}}>User</label></> : null} */}

                  </div>

                </div>
              ))}
            </div>
            {
              loader &&

              <div style={{
                width: '90%',
                display: 'flex',
                marginLeft: '10px'
              }}>
                {
                  messageText.toLowerCase().includes("hi") ||
                    messageText.toLowerCase().includes("hello") ||
                    messageText.toLowerCase().includes("how are you") ||
                    messageText.toLowerCase().includes("thank you") ?

                    null
                    :
                    <RandomPhraseComponent loader={loader} />
                }
              </div>
            }
          </div>

          {/* Chat Input */}
          <div className="chat-input">
            <form onSubmit={handleSubmit}>
              <input type="text" id="chat-input" placeholder="Send a message..." value={inputText} onChange={handleInputChange} />
              <button type="submit" className="chat-submit" id="chat-submit"><i className="mdi mdi-send" style={{ fontSize: "24px", color: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}></i></button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatBot;
