/* 
FileName:index.js
purpose:Group Chat
Developers:Rohini
 */
import React, { useEffect, useState, useReducer } from 'react';
import $ from 'jquery';
// eslint-disable-next-line
import SideBar from './sideNav';
import Header from '../Utility/TopNav/topnav';
// import RootLoader from '../../Common/Loader/RootLoader';
import { useSelector } from 'react-redux';
import { getAllMessages, getEmployees, getAllMessagesLast } from './network';
import { reducer, initialState } from './reducer';
import ChatBox from '../../Common/SquadChat';
import convertPSTtoLocalTime from '../../Common/convertPSTtoLocalTime';
import { useWebProperties } from '../../Common/webProperties';
import RootLoader from '../../Common/Loader/RootLoader';

export default function GroupChat() {

    const getUser = useSelector(state => state.auth)
    const [chatOpen, setChatOpen] = useState(false);
    const [cardInfo, setCardInfo] = useState()
    const [state, dispatch] = useReducer(reducer, initialState)
    const [searchedResults, setSearchedResults] = useState([])
    const [searchQuery, setSearchQuery] = useState(null)
    const { CHAT, SQUAD_MEMBER_NAME } = useWebProperties();
    useEffect(() => {
        getAllMessages(dispatch, getUser.user);
        getEmployees(dispatch, getUser.user)
        getAllMessagesLast(dispatch, getUser.user)
        // eslint-disable-next-line
    }, [])


    const key = 'name';

    const arrayUniqueByKey = [...new Map(state.employees.map(item =>
        [item[key], item])).values()].sort((a, b) => b.sno - a.sno);
    const sortedOne = (msg, emp) => {
        const sortedUserIds = msg
            .sort()
            .map(m => m.sendBy)
            .filter(m => m.sendBy !== null)

        const sortedUsers = [...new Set(sortedUserIds)].map(id => emp.find(u => u.id === id))

        const namesToDeleteSet = new Set(sortedUsers);

        const newArr = emp.filter((employee) => {
            return !namesToDeleteSet.has(employee);
        });
        //    const sortedEmployees = [...sortedUsers,...newArr]
        const sortedEmployees = sortedUsers.concat(newArr)
        return sortedEmployees;

    }
    const empSorted = sortedOne(state.allMessages, arrayUniqueByKey);
    const handleOpenChat = (emp, action, sno) => {
        setChatOpen(true);
        var info = {
            id: emp.id,
            employeeId: emp.employeeId,
            name: emp.name, action: action,
            designation: emp.designation,
            email: emp.email,
            mobile: emp.mobileNumber,
            userType: emp.role,
            team: emp.team,
            reportingManager: emp.reportingManager,
            functionalManager: emp.functionalManager,
            userName: emp.userName,
            userStatus: emp.workingStatus,
            device_id: emp.device_id,
            player_id: emp.player_id,
            sno: sno
        }

        setCardInfo(info);
    };
    useEffect(() => {
        if (empSorted.length > 0) {
            $(document).ready(function () {
                window.$('#example').DataTable({
                    destroy: true,
                    retrieve: true,
                    fixedHeader: true,
                    "ordering": false
                })
            })
        }
    }, [empSorted])

    const handleChatClose = () => {
        setChatOpen(false);
        getAllMessages(dispatch, getUser.user);
        getEmployees(dispatch, getUser.user);
        handleSearch(empSorted, searchQuery);
    };

    const getMessagesCount = (data, msg, emp) => {
        const msgCount = msg.filter(message => message.sendBy === emp.id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })

        return (
            <i>
                {
                    msgCount.length > 0 ?
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-10px" }}
                                onClick={(event) => handleOpenChat(emp, "UserChat", msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold", marginLeft: "-2px" }}>{msgCount.length}</span>
                        </div>
                        :
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-10px" }}
                                onClick={(event) => handleOpenChat(emp, "UserChat", msgCount)} />
                        </div>
                }
            </i>
        )
    }

    const handleSearch = (data, query) => {
        const results = [];
        for (const item of data) {
            if (item && (
                (item.name && item.name.toLowerCase().includes(query.toLowerCase())) ||
                (item.mobileNumber && item.mobileNumber.includes(query)) ||
                (item.email && item.email.toLowerCase().includes(query.toLowerCase())) ||
                (item.userName && item.userName.toLowerCase().includes(query.toLowerCase())) ||
                (item.message && item.message.toLowerCase().includes(query.toLowerCase()))
            )) {
                results.push(item);
            }
        }
        setSearchedResults(results)
    }

    return (
        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <SideBar />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card" >
                            <div className="card">
                                <div className="card-body">
                                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                                        <div>
                                            <h2 className="card-title" style={{ overflowWrap: 'break-word', color: 'blue', backgroundColor: 'transparent' }}>
                                                {CHAT}
                                            </h2>
                                        </div>
                                        <div style={{ margin: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            <label className="mr-2" style={{ fontSize: '12px' }}>
                                                Search:
                                            </label>
                                            <input
                                                style={{ height: '30px', width: '250px' }}
                                                className="form-control"
                                                type="search"
                                                value={searchQuery}
                                                onChange={(e) => {
                                                    const query = e.target.value;
                                                    setSearchQuery(query);
                                                    handleSearch(empSorted, query);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {state.employees.length !== 0 ?
                                            <div className="table-responsive">



                                                <table
                                                    search="true"
                                                    id="" className="table table-striped table-bordered"
                                                    data-pagination="true"
                                                >
                                                    <thead style={{ backgroundColor: '#F4FAF7', display: "none" }}>
                                                        <tr>
                                                            <th style={{ textTransform: "capitalize", width: '160px' }} >{SQUAD_MEMBER_NAME}</th>
                                                            {/* <th style={{ alignItems:"center" }}>Last msg</th> */}
                                                            <th style={{ width: '20px', alignItems: "center" }}>{CHAT}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            searchedResults.length > 0 ?
                                                                searchedResults.map((employee, index) => {
                                                                    const input = employee.name;
                                                                    const [name] = input.split('@');
                                                                    return (
                                                                        <tr key={index}>
                                                                            {/* {employee.email !== getUser.user.userName ? : ""} */}
                                                                            <td style={{ textTransform: "capitalize", paddingLeft: "2%", fontSize: "13px", height: "50px" }}>{name}<br /><br />
                                                                                {employee.messaged_time !== null ?
                                                                                    <div >
                                                                                        <q style={{ color: 'gray', fontSize: "9px", padding: 2, textTransform: "lowercase" }}>{convertPSTtoLocalTime(employee.messaged_time)}</q>&nbsp;&nbsp;&nbsp;
                                                                                        <span style={{ fontSize: "11px", color: 'gray', padding: 2, display: "inline-block", width: "800px", whiteSpace: 'nowrap', overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                                            <div dangerouslySetInnerHTML={{__html: employee.message}}></div>
                                                                                            </span>
                                                                                    </div> : ""}
                                                                                {/* {getLastMsgTime(state.allMessagesLast, employee) !== undefined || getLastMsgTime(state.allMessagesLast, employee) !== ""? 
                                                                    <div >
                                                                        {getLastMsgTime(state.allMessagesLast, employee) !== undefined || getLastMsgTime(state.allMessagesLast, employee) !== "" || getLastMsgTime(state.allMessagesLast, employee) !== null? 
                                                                    <span style={{color:'gray',fontSize:"8px"}}>{getLastMsgTime(state.allMessagesLast, employee)}</span> : ""}&nbsp;&nbsp;&nbsp;
                                                                    <span style={{fontSize:"11px",color:'gray', display: "inline-block",width: "75%",whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis" }}>{getLastMsg(state.allMessagesLast, employee)}</span>
                                                                    </div>: null} */}
                                                                            </td>
                                                                            {/* <td style={{ paddingLeft:"2%", float: "right",borderStyle: "none", paddingTop:"15px" }}>
                                                                        <span style={{color:'gray', display: "inline-block",width: "150px",whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis" }}>{employee.message}</span>&nbsp;&nbsp;&nbsp;
                                                                        
                                                                        <q style={{color:'gray',fontSize:"8px"}}>{employee.messaged_time}</q>
                                                                    </td> */}
                                                                            <td style={{ width: '8px', height: "35px", paddingLeft: "-10%" }}>
                                                                                <button type="button" style={{ paddingLeft: "-5%", backgroundColor: 'transparent', border: "0", width: '5px', padding: "0", marginLeft: "15px" }} >
                                                                                    {getMessagesCount(index, state.allMessages, employee)}
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })

                                                                :
                                                                empSorted !== 0 ? empSorted.map((employee, index) => {
                                                                    const input = employee.name;
                                                                    const [name] = input.split('@');
                                                                    return (
                                                                        <tr key={index}>
                                                                            {/* {employee.email !== getUser.user.userName ? : ""} */}
                                                                            <td style={{ textTransform: "capitalize", paddingLeft: "2%", fontSize: "13px", height: "50px" }}>{name}<br /><br />
                                                                                {employee.messaged_time !== null ?
                                                                                    <div >
                                                                                        <q style={{ color: 'gray', fontSize: "9px", padding: 2, textTransform: "lowercase" }}>{convertPSTtoLocalTime(employee.messaged_time)}</q>&nbsp;&nbsp;&nbsp;
                                                                                        <span style={{ fontSize: "11px", color: 'gray', padding: 2, display: "inline-block", width: "800px", whiteSpace: 'nowrap', overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                                        <div dangerouslySetInnerHTML={{__html: employee.message}}></div> 
                                                                                            </span>
                                                                                    </div> : ""}
                                                                                {/* {getLastMsgTime(state.allMessagesLast, employee) !== undefined || getLastMsgTime(state.allMessagesLast, employee) !== ""? 
                                                                    <div >
                                                                        {getLastMsgTime(state.allMessagesLast, employee) !== undefined || getLastMsgTime(state.allMessagesLast, employee) !== "" || getLastMsgTime(state.allMessagesLast, employee) !== null? 
                                                                    <span style={{color:'gray',fontSize:"8px"}}>{getLastMsgTime(state.allMessagesLast, employee)}</span> : ""}&nbsp;&nbsp;&nbsp;
                                                                    <span style={{fontSize:"11px",color:'gray', display: "inline-block",width: "75%",whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis" }}>{getLastMsg(state.allMessagesLast, employee)}</span>
                                                                    </div>: null} */}
                                                                            </td>
                                                                            {/* <td style={{ paddingLeft:"2%", float: "right",borderStyle: "none", paddingTop:"15px" }}>
                                                                        <span style={{color:'gray', display: "inline-block",width: "150px",whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis" }}>{employee.message}</span>&nbsp;&nbsp;&nbsp;
                                                                        
                                                                        <q style={{color:'gray',fontSize:"8px"}}>{employee.messaged_time}</q>
                                                                    </td> */}
                                                                            <td style={{ width: '8px', height: "35px", paddingLeft: "-10%" }}>
                                                                                <button type="button" style={{ paddingLeft: "-5%", backgroundColor: 'transparent', border: "0", width: '5px', padding: "0", marginLeft: "15px" }} >
                                                                                    {getMessagesCount(index, state.allMessages, employee)}</button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }) : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                            : <RootLoader />}

                                        {chatOpen ? (
                                            <ChatBox
                                                open={chatOpen}
                                                handleClose={handleChatClose}
                                                data={cardInfo}
                                                handleModalClose={handleChatClose}
                                            />
                                        ) : null}
                                    </div>
                                    {/* // } */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}