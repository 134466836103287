import Alert from "../../Alert";
import API from "../../Network/API";
import { isLoaded, isLoading } from "../action";
import { getGroupDetailsService } from "./readService";

// for updating chat count
export async function updateChatService(sno, dispatch, getUser) {
    const msgId = sno ? sno.map((msgId, index) => {
      return msgId.sno
    }) : null
    dispatch(isLoading());
    try {
      // eslint-disable-next-line
      var response = await API.post("group_chat.php", {
        action: "updateChat",
        corp_code: getUser.corp,
        messagedBy: getUser.empId,
        msgId: msgId ? msgId : " "
      }, {}, false);
    } catch (error) {
      Alert('error', error.message)
    }
    dispatch(isLoaded());
  }

  // for update group
export async function updateGroupService(state, mem, label, dispatch, getUser, handleClose) {
    dispatch(isLoading());
    if (state.title === "") {
      Alert("warning", "Please add Group Name ")
      dispatch(isLoaded());
    }
    else if (state.title !== "") {
      try {
        var response = await API.post("group_chat.php", {
          crop: getUser.corp,
          action: "update",
          group_name: state.title.value ? state.title.value : state.title,
          group_Id: state.id.value,
          // for members names
          members: mem,
          // for members email
          label: label,
        }, {}, false);
        if (response.status === 'True') {
          getGroupDetailsService(dispatch, getUser)
        //   Alert("success", "Group updated successfully!");
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
      handleClose()
    } else {
      Alert("warning", "Please enter required fields ")
      dispatch(isLoaded());
    }
  }