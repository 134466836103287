import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import API from '../../Common/Network/API';
import { useSelector } from 'react-redux';
import Alert from '../../Common/Alert';
import { getSubStringId } from "../../Common/SubStringConvert";
import convertPSTtoLocalTime from '../../Common/convertPSTtoLocalTime';
import $ from 'jquery';
import { useWebProperties } from '../../Common/webProperties';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

// user document model and immigration admin model also
export default function TaskInfo(props) {
    // console.log(props.data.id)
    const classNames = useStyles();
    const getUser = useSelector(state => state.auth)
    const [story, setStories] = useState([]);
    const { MAINTASK } = useWebProperties();
    useEffect(() => {
        getCommentUserStory(props.data.id)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (story.length > 0) {
            $(document).ready(function () {
                window.$('#user-info-table').DataTable({
                    destroy: true,
                    dom: 'Bfrtip',
                    aLengthMenu: [
                        [25, 50, 100, 200, -1],
                        [25, 50, 100, 200, "All"]
                    ],
                    iDisplayLength: -1,
                    buttons: [
                        { extend: 'excelHtml5', text: 'Export' }
                    ]
                })
            })
        }
    }, [story])

    //Get Comments for UserStory  Where We Have and Who can Add comments for that
    const getCommentUserStory = async (userStoryId) => {
        try {
            const response = await API.post("story_comments.php", {
                action: "get_story_comment",
                storyId: userStoryId,
                corp: getUser.user.corp,
            }, {}, false);
            // console.log(response)
            if (response.status === "True") {
                setStories(response.data)
            } else {
                // Alert('warning', response.message)
            }
        }
        catch (error) {
            Alert('error', error.message)
        }
    }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                {/* <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content modal-sm p-2" style={{ borderRadius: '10px', overflowWrap: "break-word" }}>
                                <div className="modal-body">
                                    <div>
                                        {/* <h5>Task {props.data.id}</h5> */}
                {/* {story.length > 0 ?
                                            story.map((story, index) => {
                                                return (
                                                    <p>{index+1} . {story.message} {story.fullName} on {story.statusDate}</p>
                                                );
                                            })
                                            : null} */}
                {/* <p className="text-success">{props.data.description || props.data.taskDesc} </p> */}
                {/* <p className="text-info">Path : {props.data.ideaTitle}/{props.data.moduleDesc}/{props.data.mainTaskTitle}</p>
                                        {props.data.laneId === "RoadBlock" ? null : props.data.status === "pending" ? <p className="text-info">Task TimeLeft : {props.data.timeLeft} </p>
                                            : <p className="text-info">Task ExtraHours : {props.data.extraHours} </p>} */}
                {/* </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-success" onClick={props.handleClose} style={{ borderRadius: '20px' }}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>  */}
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center" style={{ height: '80vh' }}>
                            <div className="modal-content" style={{ borderRadius: '10px' }}>
                                <div class="modal-header info-c" style={{ backgroundColor: '#D2ECDF' }}>
                                    <div className="column">
                                        {/* {/* <h5 class="modal-title">User Story -{state.groupName}</h5> */}
                                        <h6 class="modal-title">{getSubStringId(getUser.user.corp, 3)}{'-'}{props.data.us_id}  {props.data.title}</h6>
                                    </div>
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={props.handleClose} className="d-flex align-items-right" data-dismiss="modal"><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body" style={{ overflow: 'auto' }}>
                                    <table className='table table-bordered mb-1 rwd-table' id="user-info-table" style={{width:'100%',overflow:'auto'}}>
                                        <thead>
                                            <tr>
                                                <th>SNO</th>
                                                <th>NAME</th>
                                                <th>Message</th>
                                                <th>DATE</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {story.length > 0 ?
                                                story.slice(0).map((comments, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{comments.fullName}</td>
                                                            <td>{comments.message.replace('User Story', MAINTASK)}</td>
                                                            <td>{convertPSTtoLocalTime(comments.statusDate)}</td>
                                                        </tr>
                                                    )
                                                }) : null}

                                        </tbody>
                                    </table>


                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-success" onClick={props.handleClose} style={{ borderRadius: '20px' }}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}